<template>
  <div>
    <div v-if="isDesktop && showCircleTooltip" class="circle"></div>

    <div class="product-head">
      <div class="product-head-send text-center d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div @click="selectObject(apartment)" class="btn-filter d-block">
            Поділитися
          </div>

          <svg
            class="icon question-icon ml-2"
            :id="`tooltip-target-share-${apartment.id}`"
          >
            <use xlink:href="@/assets/icons/question.svg#question"></use>
          </svg>

          <b-tooltip
            :target="`tooltip-target-share-${apartment.id}`"
            triggers="hover"
          >
            При натисканні на кнопку буде скопійовано посилання на об'єкт, який
            ви можете надсилати своїм клієнтам, контактні дані будуть замінені
            на Ваші!
          </b-tooltip>
        </div>

        <div class="d-flex align-items-center">
          <div
            v-if="$route.name === 'archive'"
            class="btn-filter d-block btn-red"
            style="transform: none"
          >
            Не актуально
          </div>

          <div
            v-else
            :class="{ 'circle-arhive': isMobile && showCircleTooltip }"
          >
            <b-dropdown
              id="dropdown-right"
              right
              text="Перенести до архіву"
              class="m-2 dropdown-archive"
              size="sm"
            >
              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('rent_or_sold')"
              >
                {{ apartment.business_type === 'rent' ? 'Здано' : 'Продано' }}
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('withdrawn_from_sale')"
              >
                {{
                  apartment.business_type === 'rent'
                    ? 'Зняли з оренди'
                    : 'Знято з продажу'
                }}
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('there_is_from_owner')"
              >
                Є від власника
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('incorrect')"
              >
                Не коректна інфо
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('not_cooperate')"
              >
                Не співпрацюють
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                class="arhive-item"
                @click="transferToArchive('not_respond')"
              >
                Не виходять на зв'язок
              </b-dropdown-item>
            </b-dropdown>

            <p v-if="showCircleTooltip" class="cirle-title">
              Цей об'єкт вже може бути
              {{ apartment.business_type === 'rent' ? 'зданий' : 'проданий' }},
              скоро ми це перевіримо ;) <br />
              <span class="cirle-ok" @click="showCircleTooltip = false">
                OK
              </span>
            </p>
          </div>

          <svg
            v-if="
              isDesktop || !showCircleTooltip || apartment.is_archive !== '1'
            "
            class="icon question-icon ml-2"
            id="tooltip-target-archive"
          >
            <use xlink:href="@/assets/icons/question.svg#question"></use>
          </svg>

          <b-tooltip target="tooltip-target-archive" triggers="hover">
            Якщо знаєте, що квартира не актуальна, натисніть на червону кнопку і
            ми приберемо її в архів (За інформацію платимо 2 Easycoin).
          </b-tooltip>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="block-id">
          <span>id: {{ apartment.id }}</span>
        </div>

        <div v-if="edit" class="block-id" style="margin-right: 37px">
          <button
            class="btn btn-filter"
            @click="
              EDIT_OBJECT(apartment);
              $router.push(`/manage-object/${apartment.id}`);
            "
          >
            Редагувати
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="product-head-label">Додано:</div>

          <div class="product-head-agent-label">
            {{ apartment.created_at != null ? apartment.created_at : '' }}
          </div>
        </div>

        <div class="col-9">
          <div class="product-head-label d-flex">
            <span>Джерело: </span>

            <span class="ml-1 d-flex align-items-center">
              <template v-if="isMobile">
                {{ apartment.source === 'rieltor' ? 'Рієлтор' : 'Власник  - ' }}
              </template>

              <template v-if="apartment.author_fname && apartment.author_lname">
                {{ apartment.author_fname + ' ' + apartment.author_lname }}
                <union-list
                  v-if="apartment.unions.length"
                  :list="apartment.unions"
                />
              </template>

              <template v-else>
                {{ apartment.agency }}
              </template>
            </span>
          </div>

          <div class="product-head-agent-label">
            <div
              class="phones-item"
              v-for="(phone, i) in apartment.phone"
              :key="i"
            >
              <a :href="`tel:${getPhone(phone)}`">{{ getPhone(phone) }}</a>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isHouse">
        <span class="product-hr"></span>

        <div class="row">
          <div class="col-6">
            <p class="mb-0"><b>Газ:</b> {{ gas_object[apartment.gas] }}</p>
          </div>

          <div class="col-6">
            <p class="mb-0">
              <b>Каналізація:</b> {{ sewers_object[apartment.sewers] }}
            </p>
          </div>

          <div class="col-6">
            <p class="mb-0"><b>Вода:</b> {{ water_object[apartment.water] }}</p>
          </div>

          <div class="col-6">
            <p class="mb-0">
              <b>Опалення:</b> {{ heating_object[apartment.heating] }}
            </p>
          </div>
        </div>
      </template>

      <template v-if="isCommerce">
        <span class="product-hr"></span>

        <div class="row">
          <div v-for="option in optionsList" :key="option" class="col-6">
            <p class="mb-0">
              <b> {{ option }} </b>
            </p>
          </div>
        </div>
      </template>

      <template
        v-if="
          isLand && apartment.communications && apartment.communications.length
        "
      >
        <span class="product-hr"></span>

        <div>
          <ul
            class="list-unstyled d-flex flex-wrap justify-content-center pl-0"
          >
            <li
              v-for="(comm, commIndex) in apartment.communications"
              :key="commIndex"
              class="mr-1"
            >
              <span>{{ getLandCommunicationLabel(comm) }}</span>

              <span v-if="commIndex < apartment.communications.length - 1"
                >,
              </span>
            </li>
          </ul>
        </div>
      </template>
    </div>

    <span class="product-hr" />

    <div
      v-if="apartment.text"
      class="catalog-card-info__description"
      v-html="apartment.text"
    ></div>

    <div class="pt-2">
      <div class="position-relative">
        <template v-if="hasImages">
          <a
            v-if="canDownloadImages"
            :href="linksExport"
            class="d-flex product-download-container"
            download
            v-on.stop.prevent="exportImages(apartment.id)"
          >
            <div class="product-download-btn">
              <icon name="download_icon" class="product-download-icon" />
            </div>

            <span class="product-download-label">Скачати всі фото архівом</span>
          </a>

          <ImageCarousel
            :images="imagesCollection"
            :no-download="!canDownloadImages"
          />
        </template>

        <template v-else>
          <div class="adapt-img">
            <img
              src="@/assets/images/no-image.webp"
              alt="EasyBase | No images"
              loading="lazy"
            />
          </div>
        </template>
      </div>
    </div>

    <div class="pt-4">
      <div class="mb-1">
        <RichTextEditor
          v-model="comment"
          :id="'comment-' + apartment.id"
          name="comment"
          placeholder="Додати коментар (видно тільки мені)"
        />
      </div>

      <div
        class="d-flex justify-content-end align-items-center"
        @click="addComment"
      >
        <div class="btn-filter btn-lg">Надіслати</div>
      </div>
    </div>

    <!-- Comments List -->
    <div v-if="getComments && getComments.length">
      <div
        class="partner-review-box mb-4"
        v-for="(comment, commentIndex) in getComments"
        :key="commentIndex"
      >
        <div class="partner-review-title d-flex justify-content-between">
          <div class="d-flex w-100">
            <div class="mr-auto">
              <b>Додано: </b>
              <br />
              <span class="partner-review-date">{{ comment.get_date }}</span>
            </div>
          </div>
        </div>

        <div class="partner-review-content" v-html="comment.text"></div>
      </div>
    </div>

    <div class="text-center pt-3">
      <button
        class="btn-filter btn btn-lg d-none d-lg-inline-block"
        @click="selectObject(apartment)"
      >
        Поділитися об'єктом
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import LabelsMixin from '@/mixins/LabelsMixin';
import ResponsiveMixin from '@/mixins/responsive';
import { mapActions, mapMutations } from 'vuex';
import { ADDITIONS, LAND_COMMUNICATIONS } from '@/helpers/constants';
import UnionList from '@/elements/union-list';
import { getRouterKey } from '@/helpers/routerKey.js';
import api from '@/api';
import { getHiddenPhone } from '@/helpers/formats';
import ImageCarousel from './ImageCarousel.vue';
import RichTextEditor from './RichTextEditor.vue';

export default {
  name: 'CatalogCardInfo',
  mixins: [LabelsMixin, ResponsiveMixin],
  components: {
    UnionList,
    ImageCarousel,
    RichTextEditor
  },
  props: {
    apartment: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: () => {}
    }
  },
  data: () => {
    return {
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      comment: '',
      linksExport: '',
      showCircleTooltip: false
    };
  },
  computed: {
    images: () => {
      return [{ srcset: require('@/assets/images/' + 'no-image.webp') }];
    },
    imagesCollection() {
      return this.apartment.images.map((a) => a.img_obj);
    },
    hasImages() {
      return this.imagesCollection && this.imagesCollection.length > 0;
    },
    carText: () => [
      '<svg viewBox="0 0 22 22" class="owl-nav-left svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>',
      '<svg viewBox="0 0 22 22" class="owl-nav-right svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>'
    ],
    getComments() {
      const comments = [...this.apartment.comments];

      return comments.reverse();
    },
    isBaseRoute() {
      if (
        this.$route &&
        this.$route.name &&
        ['home', 'search'].includes(this.$route.name)
      ) {
        return true;
      }

      return false;
    },
    optionsList() {
      return (
        this.apartment.options &&
        this.apartment.options.map((i) => ADDITIONS[i])
      );
    },
    isSourceOwner() {
      return this.$store.state.filter_source.form.source.includes('owner');
    },
    isLand() {
      return this.apartment.type === 'land';
    },
    isCommerce() {
      return this.apartment.type === 'commerce';
    },
    isFlat() {
      return this.apartment.type === 'flat';
    },
    isHouse() {
      return this.apartment.type === 'house';
    },
    canDownloadImages() {
      return this.apartment.is_used_for_advertising || !this.isBaseRoute;
    }
  },
  created() {
    this.checkShowingCirleTooltip();
  },
  methods: {
    ...mapActions(['handleObject', 'handleAddCommentToShare']),
    ...mapMutations(['EDIT_OBJECT']),
    getPhone(phone) {
      if (
        this.apartment.is_archive == '2' &&
        this.apartment.source === 'owner'
      ) {
        return getHiddenPhone(phone);
      }
      return phone;
    },
    exportImages(id) {
      this.linksExport = `https://api.easybase.com.ua/v1/realty/${id}/image/all/`;
    },
    async selectObject(object) {
      const currentDate = dayjs().format('DDMMYYHHmm');

      const shareLink = `${process.env.VUE_APP_URL_EASYHATA}/${getRouterKey(
        object.type
      )}/${object.id}/rieltor/${object.share}?${currentDate}`;

      try {
        await this.$copyText(shareLink);

        this.$notify({
          group: 'app',
          type: 'success',
          text: "Посилання на об'єкт скопійовано"
        });
      } catch (error) {
        console.error('Error copying link:', error);
      }
    },
    async transferToArchive(type) {
      try {
        const response = await api.reportArchive(
          this.apartment.id,
          type,
          this.apartment.type
        );

        if (response.status === 200) {
          this.$notify({
            group: 'app',
            type: 'success',
            text: "Дякуємо за інформацію, незабаром обов'язково її перевіримо"
          });
        }
      } catch (e) {
        if (e.response.status === 400) {
          Object.values(e.response.data).forEach((val) => {
            this.$notify({ group: 'app', type: 'error', text: val });
          });
        }
      }
    },
    addComment() {
      const data = {
        id: this.apartment.id,
        comment: this.comment
      };
      this.handleAddCommentToShare(data);
      this.comment = '';
    },
    showMessage() {
      this.$notify({
        group: 'app',
        type: 'success',
        text: "Посилання на об'єкт скопійовано"
      });
    },
    checkShowingCirleTooltip() {
      if (this.apartment.realtyreports_set) {
        this.apartment.realtyreports_set.forEach((realtyreport) => {
          if (
            realtyreport.is_processed === false &&
            (realtyreport.reason === 'rent_or_sold' ||
              realtyreport.reason === 'withdrawn_from_sale')
          ) {
            this.showCircleTooltip = true;
          }
        });
      }
    },
    getLandCommunicationLabel(commValue) {
      return commValue ? LAND_COMMUNICATIONS[commValue] : '';
    }
  }
};
</script>

<style>
.icon-full {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  z-index: 11;
}
.icon-full path {
  fill: #ffffff;
}
.vgs__gallery {
  display: none;
}
.vgs__container {
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0;
}
.vgs__prev {
  left: 10%;
}
.vgs__next {
  right: 10%;
}
.wrapper-edit {
  margin-left: auto;
}
.comunication-row {
  padding-left: 15px;
}
.question-icon {
  width: 20px;
  height: 20px;
}
@media (max-width: 767px) {
  .vgs__prev {
    left: 0%;
  }
  .vgs__next {
    right: 0%;
  }
}

.block-id {
  padding-bottom: 10px;
}

.arhive-item {
  font-size: 13px;
}

.dropdown-archive > button {
  background-color: red;
  border: none;
  border-radius: 10px;
  z-index: 6000;
}

.dropdown-archive > button:hover {
  background-color: #c90a0a;
  color: white;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: red;
}

.preview-img {
  max-height: 480px;
}

.vgs__container > img,
.denied-download-img {
  pointer-events: none;
}

.circle {
  position: absolute;
  width: 250px;
  height: 200px;
  background: #308b9d;
  top: -44px;
  right: -11px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 5000;
  opacity: 0.9;
}

.circle-arhive {
  padding-top: 20px;
  background-color: #308b9d;
  height: 140px;
  width: 221px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-right: -10px;
}

.cirle-title {
  position: absolute;
  z-index: 9999999;
  font-size: 13px;
}

.cirle-ok {
  position: relative;
  z-index: 9999999;
  top: 10px;
}

.cirle-ok:hover {
  cursor: pointer;
}

#dropdown-right {
  z-index: 9999999999;
}

/* Catalog Card Info */
.catalog-card-info__description:not(:last-child) {
  margin-bottom: 1.5rem;
}

.catalog-card-info__description *:not(:last-child) {
  margin-bottom: 0;
}
</style>
